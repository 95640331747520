import React from 'react'
import './Copyrights.css'

const Copyrights = () => {
  return (
    <div className='copyright-container'>
      <h4>Copyright © 2024 SAGAYA</h4>
    </div>
  )
}

export default Copyrights
