import React from 'react'
import graph_img from '../Assets/InfoGraphics.png'
import './Body_page.css'

const BodyPage = () => {
  return (
    <div className='graphics_img'>
      <img src={graph_img} alt='InfoGraphics'/>
    </div>
  )
}

export default BodyPage
