import React, {useState} from 'react'
import header_img from '../Assets/Power_BI.png'
import logo from '../Assets/Company_Logo.png'
import './Header.css'
import {Link} from 'react-router-dom'

const Header = () => {

  const [menu, setmenu] = useState('Home')

  return (
    <div>
      <section className='Header-section'>
        <img id='Header-img' src={header_img} alt='Header-image'/>
        <div className='Sub-Header'>
                <div className='left-Sub-Header'>
                    <img src={logo}/>
                </div>

                <div className='right-Sub-Header'>
                    <ul>
                        <li onClick={()=> {setmenu("Home")}}>   <Link style={{textDecoration: 'none', color:'black' }} to='/'>Home</Link>{menu==="Home" ? <hr/>:<></>}</li>
                        <li onClick={()=> {setmenu("about")}}>  <Link style={{textDecoration: 'none', color:'black'}} to='/about'>About</Link>{menu==="about" ? <hr/>:<></>}</li>
                        <li onClick={()=> {setmenu("careers")}}><Link style={{textDecoration: 'none', color:'black' }} to='/careers'>Careers</Link>{menu==="careers" ? <hr/>:<></>}</li>
                        <li onClick={()=>{setmenu("contact")}}> <Link style={{textDecoration: 'none', color:'black' }} to='/contact'>Contact us</Link>{menu==="contact" ? <hr/>:<></>}</li>
                    </ul>
                </div>
        </div>
      </section>
    </div>
  )
}

export default Header
