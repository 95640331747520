import React from 'react'
import './Career.css'

const Career = () => {
  return (
    <div className='Career-container'>
      <h1>Career</h1>
      <p>At <b>SAGAYA</b>, we are committed to building a strong team of professionals with technical expertise. As an expert in Power BI, with extensive experience as a data engineer working with pandas DataFrames and Spark DataFrames, I am now running an IT consulting firm dedicated to delivering exceptional value to our clients.</p>

<p>We are seeking talented and driven individuals to join our team. If you are a motivated and skilled professional, we invite you to send your resume to the email address provided below. I will personally review your application and keep you informed of your status. If your skills and experience align with our needs, we would be delighted to welcome you to our team.</p>

<p>At <b>SAGAYA</b>, we value our employees and are committed to meeting your financial expectations, providing comprehensive training, and nurturing your growth. Your contributions will help us continue to deliver real value to our customers.</p>

<p>Thank you for considering this opportunity. We look forward to receiving your resume.</p>

<p>Email ID: <u>romeojoseph@sagaya.co</u></p>
    </div>
  )
}

export default Career
