import React from 'react'
import './Home.css'
import BodyPage from '../../Body/BodyPage'

const Home = () => {
  return (
    <div className='Home-container'>
      <div className='text-below-header'> 
        <h1>Our <span>Specializations</span> include</h1> 
        </div>
      <BodyPage/>
    </div>
  )
}

export default Home
