import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
    <div className='Contact-container'>
      <h1>Contact</h1>
      <p>We are always here to assist you and answer any questions you may have. Whether you are interested in our services or have any queries, please do not hesitate to get in touch with us.</p>

<p>You can reach out to us using the contact details below:</p>

<ul>
<li className='contact-list'>Email: <u>romeojoseph@sagaya.co</u></li>
<li className='contact-list'>Phone: IND +91-9790401687</li>
</ul>
<p>We look forward to hearing from you and discussing how we can help meet your business needs. Thank you for considering <b>SAGAYA</b>.</p>
    </div>
  )
}

export default Contact
