import React from 'react'
import './Footer.css'
import logo from '../Assets/Company_Logo.png'
import instagram_icon from '../Assets/instagram-icon.png'
import LinkedIn_icon from '../Assets/LinkedIn_icon.png'

const Footer = () => {
  return (
    <div className='footer-container'>
      <section className='left-section'>
        <img src={logo}/>
      </section>

      
      <section className='center-section'>
        <h3>Find Our Location</h3>
        <p><b>SAGAYA</b><br/> 
              RMZ MILLENIA BUSINESS PARK, CAMPUS 1A, NO.143, DR.M.G.R.ROAD, (NORTH VEERANAM SALAI), <br/>
              PERUNGUDI, SHOLINGANALLUR, CHENNAI-600096, <br/>
              TAMIL NADU, INDIA</p>
      </section>


      <section className='right-section'>
        <h3>Quick Links</h3>
        <div className='icons'>
          <a>
          <img className='icon-setting' src={instagram_icon}/>
          </a>
        
        <a href='https://www.linkedin.com/in/romeo-joseph-55611531b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BPkDZ9B1OTluPkXo5%2FaaAwg%3D%3D' target="_blank" rel="noopener noreferrer">
          <img className='icon-setting' src={LinkedIn_icon} alt='LinkedIn Profile'/>
        </a>
        </div>
      </section>
    </div>
  )
}

export default Footer
