import './App.css';
import Header from './Components/Header/Header';
import BodyPage from './Components/Body/BodyPage';
import Footer from './Components/Footer/Footer';
import Copyrights from './Components/Copyrights/Copyrights';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/Home/Home';
import About from './Components/Pages/About/About';
import Career from './Components/Pages/Career/Career';
import Contact from './Components/Pages/Contact/Contact';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header/>
      <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About className='about'/>}/>
      <Route path='/careers' element={<Career/>}/>
      <Route path='/contact' element={<Contact/>}/>
      </Routes>
      <Footer/>
      <Copyrights/>
      </BrowserRouter>
    </div>
  );
}

export default App;
