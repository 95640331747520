import React from 'react'
import './About.css'

const About = () => {
  return (
    <div className='About-container'>
      <h1>About Us</h1>
      
      <p>My name is <b>Romeo Joseph</b>, and I am the founder of <b>SAGAYA</b>. With eight years of past corporate experience, including a recent position at one of the top Big 4 consulting firms, I established this consulting firm with a service-driven mindset. My goal is to deliver exceptional value to our clients at an affordable price.</p>

      <p>As a Power BI expert, I specialize in DAX and have extensive experience in building sophisticated reports and dashboards. My technical skills extend to coding with Python, particularly using pandas and Spark DataFrames.</p>

      <p>At <b>SAGAYA</b>, we offer a comprehensive range of Power BI services. Whether you need report building, dashboard creation, with various data sources such as Amazon Redshift, Azure, or Excel, we are here to provide professional solutions that drive real, positive impacts for your business.</p>

      <p>For any inquiries or to discuss your specific needs, please reach out to us using the contact details below:</p>

<ol>
<li><i>Name:</i>   <b>Romeo Joseph</b></li>
<li><i>Email ID:</i>   <u><b>romeojoseph@sagaya.co</b></u></li>
<li><i>Mobile:</i>     IND <b>+91-9790401687</b></li>
</ol>

<p>We look forward to partnering with you to achieve your business goals.</p>


    </div>
  )
}

export default About
